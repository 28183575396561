<template>
  <section>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Ajouter un utilisateur
              <router-link to="/users">
                <span class="float-right btn btn-info"> retour </span>
              </router-link>
            </div>
            <div class="container">
              <form class="forms-sample"  @submit.prevent="adduser()">
                <div v-if="errors.length">
                  <ul>
                    <li
                      class="invalid-feedback"
                      v-for="error in errors"
                      :key="error"
                    >
                      - {{ error }}
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleSelectGender"
                        >Profile<span class="obligatoire">*</span></label
                      >
                      <select
                        class="form-control"
                        v-model="profile"
                        required
                        @change="onSelectChange(profile)"
                      >
                      <option value="web">Profil-Web</option>
                       <option value="delegue">Delegue</option>
                        <option value="animatrice">Animatrice</option>
                        <option value="commercial">Commercial</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4" v-if="profile=='web'">
                    <div class="form-group">
                      <label for="last"
                        >Role <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="role_id"
                        @change="onerole(role_id)"
                        
                      > 
                     
                         <option
                          v-for="role in roles"
                          :key="role._id"
                          v-bind:value="role._id"
                          v-if="role.nom!='profil delegue'"
                            
                        >
                          {{ role.nom }}
                        </option>
                    
                     </select>
                      <!-- <v-select
                        :multiple="true"
                        :closeOnClick="false"
                        v-model="zone_id"
                        :options="zones"
                        :reduce="(zones) => zones"
                        label="intitule"
                      /> -->
                    </div>
                  </div>

                  <div class="col-md-4" v-if="profile=='web'">
                    <div class="form-group" >
                      <ul   v-for="permission in one_role.permissions"
                          :key="permission._id">

                        <li>
                            {{permission.titre}}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputName1"
                        >Nom <span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="name"
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="name"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputEmail3"
                        >Adresse Email<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="email"
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword4"
                        >Télephone<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="telephone"
                        type="text"
                        class="form-control"
                        id="telephone"
                        placeholder="telephone"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputEmail3"
                        >Mot de passe<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="password"
                        type="password"
                        class="form-control"
                        id="password"
                        placeholder="mot de passe"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4" id="produit_id" style="display: none">
                    <div class="form-group">
                      <label for="last"
                        >Produit <span class="obligatoire">*</span></label
                      >
                    
                      <v-select
                        :multiple="true"
                        :closeOnClick="false"
                        v-model="produit_id"
                        :options="user"
                        :reduce="(user) => user"
                        label="reference"
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->
                  <div class="col-md-4" id="zone_id" style="display: none">
                    <div class="form-group">
                      <label for="last"
                        >Zone <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="zone_id"
                        
                      > 
                         <option
                          v-for="zone in zones"
                          :key="zone._id"
                          v-bind:value="zone._id"
                        >
                          {{ zone.intitule }}
                        </option> 
                     </select>
                      <!-- <v-select
                        :multiple="true"
                        :closeOnClick="false"
                        v-model="zone_id"
                        :options="zones"
                        :reduce="(zones) => zones"
                        label="intitule"
                      /> -->
                    </div>
                  </div>

                  <div class="col-md-4" id="specialite" style="display: none">
                    <div class="form-group">
                      <label for="exampleSelectGender"
                        >Specialité<span class="obligatoire">*</span></label
                      >
                      <select class="form-control" v-model="specialite">
                        <option>Anatomie</option>
                        <option>Anatomie et cytologie pathologique</option>
                        <option>Anesthésie réanimation</option>
                        <option>Biologie médicale</option>
                        <option>Biologie médicale option biochimie</option>
                        <option>Biologie médicale option hématologie</option>
                        <option>Biologie médicale option immunologie</option>
                        <option>Biologie médicale option microbiologie</option>
                        <option>Biologie médicale option parasitologie</option>
                        <option>Biophysique et médecine nucléaire</option>
                        <option>Carcinologie médicale</option>
                        <option>Cardiologie</option>
                        <option>Chirurgie carcinologique</option>
                        <option>Chirurgie cardio vasculaire</option>
                        <option>Chirurgie générale</option>
                        <option>Chirurgie neurologique</option>
                        <option>
                          Chirurgie orthopédique et traumatologique
                        </option>
                        <option>Chirurgie pédiatrique</option>
                        <option>
                          Chirurgie plastique réparatrice et esthétique
                        </option>
                        <option>Chirurgie thoracique</option>
                        <option>Chirurgie urologique</option>
                        <option>Chirurgie vasculaire périphérique</option>
                        <option>Dermatologie</option>
                        <option>
                          Direction Et Logistique Médico-militaire
                        </option>
                        <option>Endocrinologie</option>
                        <option>Gastro-entérologie</option>
                        <option>Génétique</option>
                        <option>Gynécologie obstétrique</option>
                        <option>Hématologie clinique</option>
                        <option>Histo-embryologie</option>
                        <option>Hygiène Nucléaire</option>
                        <option>Imagerie médicale</option>
                        <option>Maladies infectieuses</option>
                        <option>Médecine aéronautique et spatiale</option>
                        <option>Médecine de Famille</option>
                        <option>Médecine De La Plongée Sous-marine</option>
                        <option>Médecine du travail</option>
                        <option>Médecine d'urgence</option>
                        <option>Médecine générale</option>
                        <option>Médecine interne</option>
                        <option>Médecine légale</option>
                        <option>Médecine nucléaire</option>
                        <option>
                          Médecine physique, rééducation et réadaptation
                          fonctionnelle
                        </option>
                        <option>Médecine préventive et communautaire</option>
                        <option>Néphrologie</option>
                        <option>Neurologie</option>
                        <option>Nutrition et maladies nutritionnelles</option>
                        <option>Ophtalmologie</option>
                        <option>Oto-rhino-laryngologie</option>
                        <option>Pédiatrie</option>
                        <option>Pédo psychiatrie</option>
                        <option>Pharmacologie</option>
                        <option>
                          Physiologie et exploration fonctionnelle
                        </option>
                        <option>Pneumologie</option>
                        <option>Psychiatrie</option>
                        <option>Radiothérapie carcinologique</option>
                        <option>Réanimation médicale</option>
                        <option>Rhumatologie</option>
                        <option>sans spécialité</option>
                        <option>Spécialiste en médecine de famille</option>
                        <option>
                          Stomatologie et chirurgie maxillo-faciale
                        </option>
                        <option>Urologie</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                       <v-app v-show="profile=='delegue'">
 
                      <v-color-picker
                      v-model="picker"
                        dot-size="25"
                        swatches-max-height="100"
                      ></v-color-picker>
                        </v-app>
                  </div>
                     <div class="col-md-4">
                       <v-app v-show=" profile=='animatrice'">
 
                      <v-color-picker
                      v-model="picker_anim"
                        dot-size="25"
                        swatches-max-height="100"
                      ></v-color-picker>
                        </v-app>
                  </div>
                  <!-- <div class="form-group">
            <label for="formFile" class="form-label">Matricule</label>
            <input class="file-upload-default" type="file" />
            <div class="input-group col-xs-8">
              <input
                type="file"
                class="form-control file-upload-info"
                placeholder="Upload Image"
                @change="onFileChange()"
                ref="file"
              /> -->
                  <!-- 
              <span class="input-group-append">
                <button
                  class="file-upload-browse btn btn-gradient-primary"
                  type="button"
                >
                  Télécharger
                </button>
              </span>
            </div>
          </div> -->
                </div>

                <br />

                <button type="submit" class="btn btn-gradient-primary mr-2">
                  Ajouter
                </button>
                <button class="btn btn-light">Annuler</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </section>
</template>

<script>
import { HTTP } from "@/axios";

export default {
  name: "adduser",
  data() {
    return {
      errors: [],
      name: "",
      telephone: "",
       picker:"",
       picker_anim:"",
      role_id: "",
      email: "",
      specialite: "",
      password: "",
      produit_id: [],
      zone_id: "",
      produits: [],
      roles: [],                                                      
      zones: [],
      user:[],
      profile:'web',
      one_role:{},
    };
  },
  created() {
    this.getproduits();
    this.getzones();
    this.getroles();
  },
  methods: {


 onerole(id) {


HTTP
  .get("getrolesbyid/" + id)
  .then((response) => {
    this.one_role = response.data;

  })
  .then((err) => console.log(err));
},










     getproduits() {
      HTTP.get("produits/getproduits")
        .then((response) => {
          this.user = response.data;
        })
        .then((err) => console.log(err));
    },

    getroles() {
      HTTP.get("roles/getroles")
        .then((response) => {
          this.roles = response.data;
        })
        .then((err) => console.log(err));
    },

    getzones() {
      HTTP.get("zones/getzones")
        .then((response) => {
          this.zones = response.data;
        })
        .then((err) => console.log(err));
    },
    adduser() {
      if ((this.profile == "delegue")||(this.profile == "animatrice" )) {
        this.name &&
        this.telephone &&
        this.profile &&
        this.email &&
        this.password &&
        this.produit_id &&
        this.zone_id &&
        this.specialite;
         this.picker;
                 

      } else {
          this.name &&
          this.telephone &&
          this.profile &&
          this.email &&
          this.password ;
        
      } 

      
      {
        let user = {
          email: this.email,
          telephone: this.telephone,
          name: this.name,
          role_id: this.role_id,
          role: this.profile,
          color: this.picker,
          color_animatrice:this.picker_anim,
          password: this.password,
          produit_id: this.produit_id,
          zone_id: this.zone_id,
          specialite: this.specialite,
           _id: this.$route.params.id,
        };
        
        HTTP.post("users/adduser", user).then((response) => {
          console.log(response.data);
       
        Toast.fire({
            icon: "success",
            title: "user ajouté",
          });
          this.$router.push("/users");
        });
      }
      this.errors = [];

      if (!this.email) {
        this.errors.push("email required.");
      }
      if (!this.telephone) {
        this.errors.push("telephone required.");
      }

      if (!this.name) {
        this.errors.push("name required.");
      }
      if (!this.adresse) {
        this.errors.push("adresse required.");
      }
      if (!this.role) {
        this.errors.push("role required.");
      }
    },
    onSelectChange(data) {
      if (data === "delegue") {
        document.getElementById("zone_id").style.display = "block";
        document.getElementById("specialite").style.display = "block";
        document.getElementById("produit_id").style.display = "block";
      } else if (data === "animatrice") {
        document.getElementById("specialite").style.display = "none";
        document.getElementById("produit_id").style.display = "block";
        document.getElementById("zone_id").style.display = "block";
      } else {
        document.getElementById("specialite").style.display = "none";
        document.getElementById("produit_id").style.display = "none";
        document.getElementById("zone_id").style.display = "none";
      }
    },

    getCoordinates(address) {
      fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          address +
          "&key=" +
          AIzaSyB -
          RS06 -
          hFA2nZlBt -
          Tj5uT6xqURNOGu3Y
      )
        .then((response) => response.json())
        .then((data) => {
          const latitude = data.results[0].geometry.location.lat;
          const longitude = data.results[0].geometry.location.lng;
          console.log({ latitude, longitude });
        });
    },
  },
};
</script>
